import React, { ReactElement } from 'react';
import { translatableText } from '../../utils/translatable-text';
import './hero-banner.less';
import { Search } from '../search';

interface Props {
    searchField: ReactElement<typeof Search>;
    subHeader: string;
}

export const HeroBanner = (props: Props) => {
    const { searchField, subHeader } = props;

    return (
        <section className="hero-banner" data-testid="hero_banner">
            <div className="hero-banner__inner">
                <h1
                    data-testid="hero_headline"
                    className="hero-banner__headline"
                >
                    {translatableText.heroBannerHeadline.text}
                </h1>
                <p className="hero-banner__headline--secondary">{subHeader}</p>
                {searchField}
            </div>
        </section>
    );
};
