import React, { useState, useEffect } from 'react';
import { getExperiment } from '../../../statsig/statsig-client';
import {
    STATSIG_EXPERIMENTS,
    STATSIG_EXPERIMENTS_DEFAULTS
} from '../../../statsig/experiments';
import * as ct from 'countries-and-timezones';
import { useAuth } from '../../providers/auth-providers';
import analytics from '../../utils/analytics';
import CSMChatWidget from './csm-chat-widget';
import {
    checkActivePartnerContact,
    fetchUserLicenses,
    calculateEntitlementAttributes
} from './user-entitlement-service';

const CSMChatContainer = () => {
    const [shouldLoadCsmAgent, setShouldLoadCsmAgent] = useState(false);
    const { aaId } = useAuth();

    useEffect(() => {
        if (aaId) {
            setCSMWidgetConfig(aaId);
            isCSMWidgetEnabled(aaId).then((isEnabled) => {
                setShouldLoadCsmAgent(!!isEnabled);
            });
        }
    }, [aaId]);

    return shouldLoadCsmAgent && <CSMChatWidget />;
};

const isCSMWidgetEnabled = async (aaId) => {
    // Do not evaluate the experiment if the user is on the contact or requests page
    const windowPathname = window.location?.pathname;
    if (shouldExcludePathname(windowPathname)) {
        return false;
    }

    const identifiers = {
        atlassianAccountId: aaId
    };

    const customAttributes = await createExperimentAttributes(aaId);

    const csmAgentExperiment = await getExperiment(
        STATSIG_EXPERIMENTS.CSM_AI_AGENT_ON_SAC,
        identifiers,
        customAttributes
    );
    sendExperimentEvent(csmAgentExperiment, aaId);

    return csmAgentExperiment.getValue(
        'agent_enabled',
        STATSIG_EXPERIMENTS_DEFAULTS[STATSIG_EXPERIMENTS.CSM_AI_AGENT_ON_SAC][
            'agent_enabled'
        ]
    );
};

const shouldExcludePathname = (windowPathname) => {
    return (
        windowPathname.startsWith('/contact') ||
        windowPathname.startsWith('/hot-contact') ||
        windowPathname.startsWith('/partnercontact') ||
        windowPathname.startsWith('/requests') ||
        windowPathname.startsWith('/named-contact')
    );
};

const sendExperimentEvent = async (experimentResult, aaId) => {
    const eventMetadata = {
        action: 'evaluated',
        actionSubject: 'CSMWidgetExperiment',
        actionSubjectId: 'CSMWidgetExperimentEvaluated'
    };
    const attributes = {
        ...experimentResult,
        aaId
    };
    analytics.sendGASV3OperationalEvent(eventMetadata, attributes);
};

const setCSMWidgetConfig = (atlassianAccountId) => {
    window.atlassianWidgetConfig = {
        displaySubmitFlag: true,
        siteId: '2eabeee3-e9af-4ccc-b178-a7a7778cdeac',
        product: 'confluence',
        orgId: '4cjkccc0-c7jd-1aaa-k356-j5j5556abcja',
        aaId: atlassianAccountId,
        assistanceServiceConfig: {
            headers: {
                'x-issynthetic-traffic': 'false',
                'x-product': 'rovo',
                'X-Cloudid': '2eabeee3-e9af-4ccc-b178-a7a7778cdeac'
            },
            baseUrl: window.SAC_BASE_URL + '/gateway/api/assist'
        }
    };
};

const createExperimentAttributes = async (aaId) => {
    const isActivePartner = await checkActivePartnerContact();
    if (isActivePartner) {
        // If the user is an active partner, return immediately without checking licenses
        return { isPartnerContact: isActivePartner };
    }
    const userLicenses = await fetchUserLicenses();
    const userLicensesCount = userLicenses.length;
    if (userLicensesCount === 0) {
        // If there are no licenses, return immediately
        return { isPartnerContact: isActivePartner, userLicensesCount };
    }

    const {
        hipaaEntitlementPresent,
        enterpriseEntitlementPresent,
        premierEntitlementPresent,
        adminEntitlementPresent
    } = calculateEntitlementAttributes(userLicenses);

    return {
        aaId,
        sacPath: window.location?.pathname,
        isPartnerContact: isActivePartner,
        userLicensesCount,
        countryCode: getLocationInfo().countryCode,
        hipaaEntitlementPresent,
        enterpriseEntitlementPresent,
        premierEntitlementPresent,
        adminEntitlementPresent
    };
};

const getLocationInfo = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
    const countryCode = ct.getCountryForTimezone(timezone)?.id || '';

    return {
        countryCode: countryCode,
        timezone: timezone
    };
};

export default CSMChatContainer;
