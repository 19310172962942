import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    sentryCaptureEvent,
    sentryCaptureException
} from '../../../../../utils/sentry/sentry-client';
import { Deployment, SortBy } from '../../../types';

export interface SuccessfulSearchResponse {
    queryId: string;
    results: SearchArticle[];
    facets: Facets;
    // Make required after v2
    pagination: Pagination;
}

interface FailedSearchResponse {
    timestamp: number;
    status: number;
    error: string;
    path: string;
}

interface SearchArticle {
    url: string;
    id: string;
    title: string;
    description: string;
    updatedDate: string;
    likes: number;
    views: number;
    version?: string;
    products: {
        platform: string[];
    }[];
}

interface FacetInfo {
    count: number;
    name: string;
}

interface FacetPlatform extends FacetInfo {
    products: { [key: string]: FacetInfo };
}

interface Facets {
    contentType: {
        [key: string]: FacetInfo;
    };
    platform: {
        [key: string]: FacetPlatform;
    };
}

interface Pagination {
    from: number;
    size: number;
    total: number;
}

export interface SearchQuery {
    deployment?: Deployment;
    productsQueryValue?: string[];
    contentTypes?: string[];
    version?: string[];
    sortByQueryValue?: SortBy;
    pageIndex?: number;
}

// These are limitations of Search API v2
export const NUM_OF_RESULTS_PER_PAGE = 10;
export const MAX_NUM_OF_RESULTS = 100;

export const fetchSearchResults = createAsyncThunk(
    'search/fetchSearchResults',
    // eslint-disable-next-line complexity
    async ({
        searchTerm,
        query,
        featureFlags
    }: {
        searchTerm: string;
        query: SearchQuery;
        featureFlags: {
            isSearchTermHighlightingEnabled?: boolean;
            isSacSearch2Enabled?: boolean;
        };
    }) => {
        const { isSearchTermHighlightingEnabled, isSacSearch2Enabled } =
            featureFlags;

        const HA_OVERRIDE_DOMAINS = new Set([
            'localhost',
            'support.dev.internal.atlassian.com'
        ]);

        const searchRoute = isSacSearch2Enabled
            ? '/gateway/api/help-content/v2/search'
            : '/gateway/api/help-content/content/search';

        const helpAggregatorUrl = HA_OVERRIDE_DOMAINS.has(
            window.location.hostname
        )
            ? `https://support.stg.internal.atlassian.com${searchRoute}`
            : searchRoute;

        const requestBody = isSacSearch2Enabled
            ? {
                  query: searchTerm,
                  facetFilters: {
                      ...(query.contentTypes?.[0] && {
                          contentTypes: query.contentTypes
                      }),
                      ...((query.deployment ||
                          query.productsQueryValue?.[0] ||
                          query.version?.[0]) && {
                          products: [
                              {
                                  ...(query.deployment && {
                                      platform: query.deployment
                                  }),
                                  ...(query.productsQueryValue?.[0] && {
                                      key: query.productsQueryValue?.[0]
                                  }),
                                  ...(query.version?.[0] && {
                                      versions: query.version
                                  })
                              }
                          ]
                      })
                  },
                  highlights: { enable: isSearchTermHighlightingEnabled },
                  pagination: {
                      from: query.pageIndex ?? 0,
                      size: NUM_OF_RESULTS_PER_PAGE
                  },
                  language: 'en',
                  facets: {
                      enable: true
                  },
                  sort: {
                      by: query.sortByQueryValue
                  }
              }
            : {
                  query: searchTerm,
                  highlightSearchTerms: true,
                  filters: {
                      platform: query.deployment,
                      // TODO: since not all right products, default (all) has limited results
                      product: query.productsQueryValue,
                      contentTypes: query.contentTypes,
                      version: query.version
                  }
              };

        try {
            const response = await fetch(helpAggregatorUrl, {
                body: JSON.stringify(requestBody),
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'same-origin',
                    authority: 'support.dev.internal.atlassian.com'
                },
                method: 'POST'
            });

            const data = await response.json();
            const queryId = isSacSearch2Enabled
                ? response.headers.get('X-Query-Id')
                : data.queryId;
            if (!response.ok) {
                const { error } = data as FailedSearchResponse;
                sentryCaptureEvent({
                    event: {
                        message: error,
                        level: 'error',
                        contexts: {
                            response: {
                                status_code: response.status
                            }
                        },
                        extra: {
                            queryId,
                            data
                        }
                    }
                });
            }

            return {
                queryId,
                results: isSacSearch2Enabled ? data.results : data.items,
                facets: data.facets,
                pagination: data.pagination
            };
        } catch (error) {
            sentryCaptureException({
                exception: error
            });
        }
    }
);
