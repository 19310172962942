import React from 'react';
import { translatableText } from '../../utils/translatable-text';
import './results-text.less';

interface ResultsTextProps {
    searchTerm: string;
    startCount: number;
    endCount: number;
    totalCount: number;
}

const formatResultText = ({
    searchTerm,
    startCount,
    endCount,
    totalCount
}: ResultsTextProps): string => {
    return translatableText.resultText.text
        .replace('startCount', startCount.toString())
        .replace('endCount', endCount.toString())
        .replace('totalCount', totalCount.toString())
        .replace('searchTerm', searchTerm);
};

export const ResultsText = ({
    searchTerm,
    startCount,
    endCount,
    totalCount
}: ResultsTextProps) => {
    const resultsText = formatResultText({
        searchTerm,
        startCount,
        endCount,
        totalCount
    });
    return (
        <span data-testid="total-results" className="results-text">
            {resultsText}
        </span>
    );
};
