import React, { useEffect, useState } from 'react';
import {
    DropdownMenuStateless,
    DropdownItemGroup,
    DropdownItem
} from '@atlaskit/dropdown-menu';
import './filter-selector.less';
import { FilterValue } from '../types';
import { useSelector, useDispatch } from 'react-redux';
import { setResetFilters } from '../state/filter-reducer';

export interface FilterProps {
    filterTitle: string;
    filterValues: FilterValue[];
    setQueryValues: any;
    allowFilterAll?: boolean;
    queryByString?: boolean;
    titleReset?: boolean;
    setTitleReset?: any;
    defaultSelected?: string;
    shouldPulse?: boolean;
    setShouldPulse?: any;
    showBorder?: boolean;
    isSacSearch2Enabled?: boolean;
}

const FilterSelector = ({
    filterTitle,
    filterValues,
    setQueryValues,
    allowFilterAll = true,
    queryByString = false,
    titleReset,
    setTitleReset,
    defaultSelected,
    shouldPulse = false,
    setShouldPulse,
    showBorder = false,
    isSacSearch2Enabled
}: FilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(
        defaultSelected ? defaultSelected : filterTitle
    );

    const dispatch = useDispatch();

    const { resetFilters } = useSelector((state: any) => state.filter);

    // Clear filter button
    useEffect(() => {
        if (resetFilters) {
            setSelected(defaultSelected ? defaultSelected : filterTitle);
            dispatch(setResetFilters(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetFilters]);

    // Edge case for when the title needs to be reset (used in product/deployment coupled filters)
    useEffect(() => {
        if (titleReset) {
            setSelected(defaultSelected ? defaultSelected : filterTitle);
            setTitleReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleReset, setTitleReset]);

    const classes = ['dropdown-container'];
    if (showBorder) {
        classes.push('dropdown-container_border');
    }
    if (shouldPulse) {
        classes.push('dropdown-container__pulse');
    }
    if (isSacSearch2Enabled) {
        classes.push('dropdown-container__search-v2');
    }

    return (
        <div
            className={classes.join(' ')}
            data-testid={`filter-selector-${filterTitle
                .replace(/ /g, '-')
                .toLowerCase()}`}
        >
            <DropdownMenuStateless
                trigger={selected}
                triggerType="button"
                isOpen={isOpen}
                onOpenChange={({
                    isOpen: isOpenedNow
                }: {
                    isOpen: boolean;
                }) => {
                    setIsOpen(isOpenedNow);
                }}
            >
                <DropdownItemGroup title={filterTitle}>
                    {allowFilterAll && (
                        <DropdownItem
                            key={`All-${filterTitle}s`}
                            onClick={() => {
                                setSelected(`All ${filterTitle}s`);
                                if (setShouldPulse) {
                                    setShouldPulse();
                                }
                                // TODO: This is a hack for sending an empty array when selecting All Products.
                                // Once other filters expect an empty array, the else block can be removed.
                                if (filterTitle === 'Product') {
                                    setQueryValues([]);
                                } else {
                                    setQueryValues(
                                        filterValues.map(({ key }) => key)
                                    );
                                }
                            }}
                        >
                            {`All ${filterTitle}s`}
                        </DropdownItem>
                    )}
                    {filterValues.map(({ name, key }) => (
                        <DropdownItem
                            key={name}
                            onClick={() => {
                                setSelected(name);
                                if (setShouldPulse) {
                                    setShouldPulse();
                                }
                                setQueryValues(queryByString ? key : [key]);
                            }}
                        >
                            {name}
                        </DropdownItem>
                    ))}
                </DropdownItemGroup>
            </DropdownMenuStateless>
        </div>
    );
};

export default FilterSelector;
