import React from 'react';
import Script from 'react-load-script';

const CSMChatWidget = () => {
    return (
        <Script
            url={window.CSM_CHAT_WIDGET_BASE_URL + '/assets/loader.js'}
            attributes={{ async: true }}
        />
    );
};

export default CSMChatWidget;
