import axios from 'axios';

const PRODUCT_KEY = {
    ATLASSIAN_ORG: 'atlassian.organisation',
    ATLASSIAN_CLOUD: 'atlassian.cloud'
};

/* eslint-disable-next-line complexity */
export const getNormalizedEntitlement = (entitlement, parentEntitlement) => {
    return {
        productKey: (entitlement?.key || entitlement?.product?.key)?.replace(
            '.ondemand',
            ''
        ),
        sen: entitlement.sen,
        hostingType:
            entitlement?.product?.hostingType || entitlement?.hostingType,
        supportLevel: getCompositeSupportLevel(entitlement, parentEntitlement),
        admin: entitlement?.product?.admin || entitlement?.admin,
        unitCount: getPropFromEntitlementOrParent(
            entitlement,
            parentEntitlement,
            'unitCount'
        ),
        hipaaEnabled:
            getPropFromEntitlementOrParent(
                entitlement,
                parentEntitlement,
                'hipaaEnabled'
            ) ?? false,
        type: getPropFromEntitlementOrParent(
            entitlement,
            parentEntitlement,
            'type'
        ),
        // parent entitlement overrides
        expired:
            parentEntitlement &&
            typeof parentEntitlement.expired !== 'undefined'
                ? parentEntitlement.expired
                : entitlement.expired,
        cloudUrl: parentEntitlement?.cloudUrl || entitlement?.cloudUrl,
        cloudId: parentEntitlement?.cloudSiteId || entitlement?.cloudSiteId,
        entitlementId: entitlement?.ccpEntitlementId || entitlement?.sen
    };
};

export const checkActivePartnerContact = async () => {
    try {
        const url = constructCCGPartnerInformationUrl();
        const apiOutput = await axios.get(url, {
            withCredentials: true
        });
        return apiOutput?.data?.activePartnerContact || false;
    } catch {
        return false;
    }
};

export const fetchUserLicenses = async () => {
    try {
        const url = constructCCGOverviewUrl();
        const apiOutput = await axios.get(url, {
            withCredentials: true
        });
        return apiOutput?.data?.entitlements || [];
    } catch {
        return [];
    }
};

export const calculateEntitlementAttributes = (entitlements) => {
    let hipaaEntitlementPresent = false;
    let enterpriseEntitlementPresent = false;
    let premierEntitlementPresent = false;
    let adminEntitlementPresent = false;
    const updateEntitlementFlags = (entitlement) => {
        const { hipaaEnabled, supportLevel, admin } = entitlement;
        if (hipaaEnabled) {
            hipaaEntitlementPresent = true;
        }
        if (supportLevel === 'ENTERPRISE') {
            enterpriseEntitlementPresent = true;
        }
        if (supportLevel === 'PREMIER') {
            premierEntitlementPresent = true;
        }
        if (admin) {
            adminEntitlementPresent = true;
        }
    };
    for (const entitlement of entitlements) {
        if (!entitlement.expired) {
            const isAtlassianCloudOrOrg =
                entitlement.product.key === PRODUCT_KEY.ATLASSIAN_CLOUD ||
                entitlement.product.key === PRODUCT_KEY.ATLASSIAN_ORG;
            if (isAtlassianCloudOrOrg && entitlement.product.children) {
                entitlement.product.children.forEach((child) => {
                    const normalizedEntitlement = getNormalizedEntitlement(
                        child,
                        entitlement
                    );
                    updateEntitlementFlags(normalizedEntitlement);
                });
            } else {
                const normalizedEntitlement = getNormalizedEntitlement(
                    entitlement,
                    null
                );
                updateEntitlementFlags(normalizedEntitlement);
            }
        }
    }
    return {
        hipaaEntitlementPresent,
        enterpriseEntitlementPresent,
        premierEntitlementPresent,
        adminEntitlementPresent
    };
};

const getCompositeSupportLevel = (entitlement, parentEntitlement) => {
    return entitlement.supportLevel || parentEntitlement?.supportLevel || null;
};

const getPropFromEntitlementOrParent = (
    entitlement,
    parentEntitlement,
    prop
) => {
    return entitlement[prop] ? entitlement[prop] : parentEntitlement?.[prop];
};

const constructCCGPartnerInformationUrl = () =>
    window.SAC_BASE_URL +
    '/gateway/api/customer-context/customer/partner-information';

const constructCCGOverviewUrl = () =>
    window.SAC_BASE_URL + '/gateway/api/customer-context/customer/overview';
